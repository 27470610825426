import {
  AUTHENTICATION_METHOD,
  BACKGROUND_TYPES,
  FILLING_TYPES,
  LOGIN_POSITIONS,
} from 'common/constants/Constants';

/**
 * 全局通用的类型
 * */
export enum PersonVerify {
  mobile = 'mobile',
  weChat = 'wechat',
}

/** 用户信息 */
export interface IUserInfo {
  /** 企业下的用户信息 */
  accountUser: {
    adminForbidden: string;
    name: string;
    avatarUrl: string;
    isCreator: boolean;
    username: string;
    unionId: string;
    aliasId: string;
    units: string[];
    passwordInitialized: boolean;
    fuzzyMobile: string;
    isPasswordSet: boolean;
    mobile: string;
    email: string;
    employeeNo: string;
    emailAccount: string;
    joinTime: string;
    position: string;
    isEmailPasswordSet: boolean;
  };
  /** 用户信息 */
  user: {
    fuzzyMobile: string;
    isCreator: boolean;
    isPasswordSet: boolean;
    isWeComBound: boolean;
    weChat: string;
    personVerify?: PersonVerify[];
  };
  authenticationMethod: AUTHENTICATION_METHOD | undefined;
  personStatus: string;
}

/** 企业信息 */
export interface IAccount extends IResponseType {
  domain: string;
  accountId: string;
  accountType: number;
  ssoSwitch: boolean;
  logoUrl: string;
  name: string;
  sourceName: string;
  usernameStatus: string;
  domainModified: boolean;
  nameUpdateAt: number;
  nameCanChange: boolean;
  emailDomains: string[];
  uin: string;
  enterpriseSize: string;
  industryType: string;
  certifyStatus: number;
  certifyMsg: string;
  certifyCompleteTime: string; // 企业认证成功时间
}

export interface IBrands {
  position: LOGIN_POSITIONS;
  backgroundType: BACKGROUND_TYPES;
  imageUrl: string;
  fillingType: FILLING_TYPES;
  color: string;
  customCssEnabled: boolean;
  css: string;
  template: string;
  id?: string;
}

export type TCaptchaOption =
  | { ticket: string; randStr: string }
  | { sessionId: string; sig: string; token: string };
export type TCaptchaOptions = {
  appId: string;
  cloudType: string;
  appKey: string;
  scene: string;
};

export enum IDENTITY_TYPE {
  OIDC = 'OIDC_V0',
  SAML = 'SAML2_V1',
  SAML_LEGACY = 'SAML2_V0',
  WEWORK = 'WEWORK_V1',
  LARK = 'FEISHU_V0',
  AD = 'AD_V1',
  AD_V0 = 'AD_V0',
  DINGTALK = 'DINGTALK_V0',
  CAS = 'CAS_V0',
  WEWORK_V0 = 'WEWORK_V0',
  OAUTH2 = 'OAUTH2_V0',
  PASSWORD = 'PASSWORD_V0', // 用户名密码
  JWT = 'JWT_V0',
}

export interface IResetPasswordUrlParams {
  reset_token: string;
}

export type IResponseType<TData = Record<string, any>> = TData & {
  errCode?: string;
  errMessage?: string;
  data?: TData;
};

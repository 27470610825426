import qs from 'qs';
import { AuthnApi as CommonAuthnApi } from 'common/api';
import { IDENTITY_TYPE } from 'common/constants/Types';
import Utils from 'common/utils';
import captchaRequest from 'common/utils/captchaRequest';
import JSRuntime from 'common/utils/JSRuntime';
import { AuthnApi } from 'idpBase/api';
import { AuthnApi as AuthnApiNew } from 'idpBase/http';
import { TGlobalStore, TUpdateStore } from 'idpBase/store';
import { LOGIN_ERR_MSG, SSO_STEP } from './constants/Constants';
import { IIdentity, ILoginParams } from './constants/Types';

class SSOLoginStore {
  public readonly global: TGlobalStore;
  public updateStore: TUpdateStore<SSOLoginStore>;
  public resetStore: () => void;

  public isBlockLoading = true;
  public step: SSO_STEP = SSO_STEP.ACCOUNT;
  public identities: IIdentity[] = []; // 认证源列表
  public currentIdentity: IIdentity | undefined = undefined; // TODO 整理删除
  public accountId = '';
  public loginErrMsg: string | undefined = undefined;
  public locationIdpId = ''; // 从地址栏获得的IdP id
  public isCustomAccountFinished = false;
  public isValidateLoading = false;
  public domainLoginResult: Record<string, any> | null = null;
  public redirectTo: (path: string) => void = () => {};
  /*
    当前登录方式对应的state值
    使用RedirectIdentity类方法直接跳转时，是通过参数传的stateId
    在组件里使用时，用的是store里的stateId
  */
  public stateId = '';
  public isStateIdLoading = false;

  // 点击其他认证源loading
  public isSSOSuccessLoading = false;

  public async initDomain() {
    if (JSRuntime.isCustomDomain || this.global.rootStore.accountId) {
      const res = await AuthnApiNew.loginByEmailOrDomain(
        JSRuntime.isCustomDomain
          ? { domainName: window.location.hostname }
          : { accountId: this.global.rootStore.accountId },
      );

      if (res.errCode) {
        const { errCode, errMessage } = res;
        const query: Record<string, string> = { errCode };
        if (errMessage && !LOGIN_ERR_MSG[errCode]) query.errMessage = errMessage;
        this.redirectTo(`${'/sso_login/error'}?${qs.stringify(query)}`);
      }

      if (res.domain && !JSRuntime.isCustomDomain) {
        this.jumpToSsoDomain(res.domain);
        return;
      }

      await this.global.rootStore.getSettings({ accountId: res.accountId });

      this.updateStore({ isBlockLoading: false, domainLoginResult: res });
      return res;
    }
    // 优化不带企业域名的地址情况
    Utils.handleNoAuth();
  }
  // AD 等认证源的登录
  public loginIdentity = (
    identityType: IDENTITY_TYPE,
    identityId: string,
    data: { credential: string; isTest?: boolean },
    curAccountId?: string,
  ) =>
    identityType === IDENTITY_TYPE.AD_V0
      ? AuthnApi.loginIdentity(curAccountId || this.accountId, identityId, data)
      : captchaRequest(
          (captchaVerification) =>
            AuthnApiNew.loginByAd(identityId, {
              ...data,
              account_id: curAccountId || this.accountId,
              captchaVerification,
            }) as any,
        );

  /*
    申请sso登录, 获取当前sso方式对应的state值
    在SSOAccount里用时，需要通过参数传accountId和currentIdentity(store里还没有)
    在Methods组件里，使用的是store里的accountId和currentIdentity
  */
  public getSSOState(accountId?: string, currentIdentity?: IIdentity) {
    this.updateStore({ isStateIdLoading: true });
    const realAccountId = accountId || this.accountId;
    const identity = currentIdentity || this.currentIdentity;
    const idpInstanceId = m.get(identity, 'id');

    return CommonAuthnApi.getSSOLoginState(realAccountId, idpInstanceId)
      .then((res: { state: string }) => {
        this.updateStore({ stateId: m.get(res, 'state') });
        return Promise.resolve(m.get(res, 'state'));
      })
      .catch((err) => {
        this.global.handleError(err);
      })
      .finally(() => {
        this.updateStore({ isStateIdLoading: false });
      });
  }

  // 登录页面账号密码登录
  public login(params: ILoginParams) {
    return AuthnApi.loginEnterprise(params);
  }

  public jumpToSsoDomain(sso: string) {
    const configUrl = new URL(window.ONEID_PUBLIC_ORIGINAL_IDP_SITE);
    const { pathname, protocol, search } = window.location;
    const hash = Utils.getPureHashPath();

    Utils.safeRedirect(
      `${protocol}//${sso}.${configUrl.host}${pathname}${search}${
        hash.includes('#/sso_login/identity') ? hash : `${hash}/identity`
      }`,
    );
  }
}

export default SSOLoginStore;

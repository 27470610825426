import type { WebConfig } from '@tencent/aegis-web-sdk-v2';
import AegisV2 from '@tencent/aegis-web-sdk-v2';
import { JSONParse } from '@tencent/oneid-utils';
import {
  AEGIS_IGNORE_DOMAINS,
  AEGIS_IGNORE_MESSAGES,
  AEGIS_IGNORE_REGEXPS,
} from 'common/constants/Constants';
import JSRuntime from './JSRuntime';

/**
 * aegis 默认配置
 */
export const defaultConfig: WebConfig = {
  hostUrl: { url: 'https://galileotelemetry.tencent.com/collect' },
  env: JSRuntime.isProductSite ? AegisV2.environment.PROD : AegisV2.environment.TEST,
  repeat: 0,
  plugin: {
    spa: true,
    apiSpeed: true,
    assetSpeed: true,
    session: true,
    blankScreen: true,
    api: {
      // 收集接口请求参数和返回信息
      apiDetail: true,
      // http status === 200 && 有retcode不上报，属于正常业务报错
      retCodeHandler: (data: string, url: string, ctx: { code: string; isErr: boolean }) => {
        return { isErr: false, code: ctx.code };
      },
      resHeaders: ['x-trace-id'],
    },
  },
};

/**
 * 处理上报数据
 * @param pageDomains 页面域名
 */
export const handleBeforeSend = (pageDomains: string[]) => {
  return (logs: any, aegisInfo: any = {}) => {
    // 页面来源非本项目使用的域名不上报
    if (!pageDomains.some((domain) => String(aegisInfo.snapshootInfo?.from).startsWith(domain))) {
      return [];
    }
    return logs.filter((log: any) => {
      const { type = '', status = 200, url = '', msg = '', data = '', level = '' } = log;

      // 忽略不需要上报的错误日志
      if (
        AEGIS_IGNORE_MESSAGES.some((ignoreMsg) => msg.includes(ignoreMsg)) ||
        AEGIS_IGNORE_REGEXPS.some((msgRegExp) => msgRegExp.test(msg))
      ) {
        return false;
      }

      if (type === 'normal') {
        // 文件加载错误（js、css、图片、媒体）
        if (
          level === 'script_error' ||
          level === 'image_error' ||
          level === 'css_error' ||
          level === 'media_error'
        ) {
          return pageDomains.some((domain) => msg.includes(domain));
        }
      }

      // 不上报 http status === 200
      // 不上报接口状态码4xx且有 data.errCode 或 data.message 的报错，因为对于老接口、老网关，这是正常的业务报错
      // 不上报忽略的域名，如埋点域名
      if (type === 'api') {
        if (
          status === 200 ||
          (data &&
            /^4\d{2,}/.test(status) &&
            (JSONParse(data)?.errCode || JSONParse(data)?.message)) ||
          AEGIS_IGNORE_DOMAINS.some((domain) => url.startsWith(domain))
        ) {
          return false;
        }
      }
      return true;
    });
  };
};
